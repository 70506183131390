
import Vue, { PropType } from 'vue'
import PlayerElement from '~/components/player/Element.vue'
import { pojoToSermon, Sermon } from '~/models/sermon'
import { PlayerMediaType } from '~/assets/ts/enums'
import LoadingElement from '~/components/_general/LoadingElement.vue'

export default Vue.extend({
  name: 'MarkdownPlayer',
  components: { LoadingElement, PlayerElement },
  props: {
    type: {
      type: String as PropType<'video' | 'audio'>,
      default: 'audio',
    },
    sermonId: {
      type: [String, Number],
      default: undefined,
    },
    editor: {
      type: Boolean,
    },
    /** Indicates that this player will be removed in the final document */
    removed: {
      type: Boolean,
    },
  },
  data() {
    const rendered = !!this.$store.getters['sermons/sermon'](
      this.sermonId?.toString()
    )
    return {
      rendered,
    }
  },
  computed: {
    sermon(): Sermon | undefined {
      const pojo = this.$store.getters['sermons/sermon'](
        this.sermonId?.toString()
      )
      return pojoToSermon(pojo)
    },
    invalidMedia(): boolean {
      if (!this.sermon) return false
      return this.video ? !this.sermon.hasVideo : !this.sermon.hasAudio
    },
    video(): boolean {
      return this.type === 'video'
    },
    mediaType(): PlayerMediaType {
      return this.video ? PlayerMediaType.Video : PlayerMediaType.Audio
    },
  },
  watch: {
    sermonId() {
      this.getSermon()
    },
  },
  async mounted() {
    await this.getSermon()
  },
  methods: {
    async getSermon() {
      if (!this.sermonId) return
      if (this.removed) return
      if (this.sermon) return
      this.rendered = false
      await this.$store.dispatch('sermons/fetchSermon', {
        sermonID: this.sermonId,
        lite: false,
      })
    },
  },
})
