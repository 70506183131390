
import Vue, { PropType } from 'vue'
import MarkdownAdvancedElement from '~/components/markdown/AdvancedElement.vue'
import SpeakerElement from '~/components/_general/SpeakerElement.vue'
import HorizontalRule from '~/components/_general/HorizontalRule.vue'
import BlbTagger from '~/components/_general/BlbTagger.vue'
import { Speaker } from '~/models/speaker'
import { Broadcaster } from '~/models/broadcaster'
import { SpeakerDisplay } from '~/assets/ts/enums'

export default Vue.extend({
  name: 'ArticleWrapper',
  components: {
    BlbTagger,
    HorizontalRule,
    SpeakerElement,
    MarkdownAdvancedElement,
  },
  props: {
    content: {
      type: String,
      required: true,
    },
    print: {
      type: Boolean,
    },
    /** re-checks BLB tagger when this value changes */
    blbKey: {
      type: String,
      default: 'article',
    },
    speaker: {
      type: Object as PropType<Speaker>,
      default: undefined,
    },
    broadcaster: {
      type: Object as PropType<Broadcaster>,
      default: undefined,
    },
  },
  computed: {
    speakerDisplay(): SpeakerDisplay {
      return SpeakerDisplay.Expanded
    },
  },
  watch: {
    blbKey() {
      if (this.print) return
      const blb = this.$refs.blb as Vue
      if (blb) {
        blb.$emit('init')
      }
    },
  },
})
