
import Vue, { PropType } from 'vue'
import { LoadScript, waitOneFrame } from '~/assets/ts/utils/misc'
import { Broadcaster } from '~/models/broadcaster'

export type BlbTranslation =
  | 'KJV'
  | 'NKJV'
  | 'NLT'
  | 'NIV'
  | 'ESV'
  | 'CSB'
  | 'NASB20'
  | 'NASB95'
  | 'LSB'
  | 'AMP'
  | 'NET'
  | 'RSV'
  | 'ASV'
  | 'YLT'
  | 'DBY'
  | 'WEB'
  | 'HNV'
  | 'RVR60'
  | 'VUL'
  | 'WLC'
  | 'BES'
  | 'LXX'
  | 'mGNT'
  | 'TR'
  | 'NAV'
  | 'SVD'

interface BlbTagger {
  /**
   * What default translation should we display the verses in?
   * @default 'NKJV'
   * */
  Translation: BlbTranslation | string
  /**
   * What should we do with references that are already links with an <a> tag?
   * @default 'all'
   *  */
  Hyperlinks?: 'all' | 'none' | 'hover'
  /**
   * If a Scripture reference is followed by a translation abbreviation (e.g., John 1:1 ESV), do you want to hide the abbreviation?
   * @default false
   * */
  HideTranslationAbbrev?: boolean
  /**
   * When a link in the hover is clicked, should it open in a new window?
   * @default true
   * */
  TargetNewWindow?: boolean
  /**
   * Do you want the verses to show up line-by-line, or in paragraphs?
   * @default 'par'
   * */
  Style?: 'par' | 'line'
  /**
   * A space or comma delineated list of HTML elements to skip over when searching for scripture references. This applies to your entire HTML document.
   * Already included: applet, hr, head, img, input, meta, script, select, textarea
   * */
  NoSearchTagNames?: string
  /**
   * A space or comma delineated list of CSS classes which designate elements to skip over when searching for scripture references.
   * */
  NoSearchClassNames?: string
  pageInit: () => void
}

/** https://www.blueletterbible.org/webtools/BLB_ScriptTagger.cfm */
interface Blb {
  Tagger: BlbTagger
}

declare global {
  interface Window {
    BLB?: Blb
  }
}

export default Vue.extend({
  name: 'BlbTagger',
  props: {
    bibleVersion: {
      type: String as PropType<BlbTranslation>,
      default: 'KJV',
    },
    broadcaster: {
      type: Object as PropType<Broadcaster>,
      default: undefined,
    },
  },
  computed: {
    translation(): BlbTranslation | string {
      return this.broadcaster?.bibleVersion || this.bibleVersion
    },
  },
  watch: {
    bibleVersion() {
      this.setTranslation()
    },
    broadcaster() {
      this.setTranslation()
    },
  },
  async mounted() {
    this.$on('init', this.init)
    await this.init()
    if (window.BLB?.Tagger) return
    try {
      // https://www.blueletterbible.org/webtools/BLB_ScriptTagger.cfm
      await LoadScript(
        'https://www.blueletterbible.org/assets-v3/scripts/blbToolTip/BLB_ScriptTagger-min.js'
      )
      this.setTranslation()
    } catch (e) {
      console.warn(e)
    }
  },
  methods: {
    async init() {
      const tagger = window.BLB?.Tagger
      if (tagger) {
        this.setTranslation()
        await waitOneFrame()
        tagger.pageInit()
      }
    },
    setTranslation() {
      const tagger = window.BLB?.Tagger
      if (!tagger) {
        console.warn('BLB: could not find plugin')
        return
      }
      tagger.Translation = this.translation
      tagger.Hyperlinks = 'hover'
      tagger.NoSearchTagNames = 'time button h1 h2 h3'
      tagger.TargetNewWindow = true
      console.log('BLB: Set translation')
    },
  },
})
